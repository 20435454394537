/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.brand-logo {
  img {
    width: 36px;
    margin-top: -7px;
  }
}
@media (min-width: 768px) {
  .customerInformationCard .row > .col-md-8 {
    border-right: 1px solid #ebe9f1;
  }
  .timeline .timeline-item .timeline-event .timeline-event-time {
    white-space: nowrap;
  }
}

.add-event-form button {
  float: right;
}
.add-event-form button span {
  padding-left: 0.5rem;
}

.timeline .timeline-item {
  padding-left: 1.5rem;
}

.nav-tabs .nav-link {
  padding-left: 0;
}
.htmlContainer {
  p * {
    background-color: initial !important;
  }
}

.select__menu {
  z-index: 3 !important;
}

.timeline-event p {
  white-space: pre-line;
}
.autocomplete-container .suggestions-list {
  width: 98% !important;
  left: 1%;
}
.buttonAnimation {
  .buttonOpacity {
    opacity: 0;
    transition: opacity .3s;
  }
}

.buttonAnimation:hover {
  .buttonOpacity{
    opacity: 1;
  }
}

.timeline .timeline-item .timeline-point i, .timeline .timeline-item .timeline-point svg {
  color: #fff !important;
}

.eventContainer .eventItem {
  opacity: 0;
  transition: opacity .3s;
}

.eventContainer:hover {
    .eventItem {
        opacity: 1;
    }
}

.dataEffect {
  padding: 0;
  margin: 0;
  // Set delay per List Item
  @for $i from 1 through 5000 {
    div:nth-child(#{$i}) {
      animation-delay: 0.02s * $i;
    }
  }
  .rdt_TableRow {
    transition: all 0.2s, background 0s, border-color 0s, color 0s;
    animation: fadeIn 0.5s linear;
    animation-fill-mode: both;
    position: relative;
    background: white;
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 10px 0 #ebe9f1;
      transition: all 0.2s;
      z-index: 1;
    }
  }
}